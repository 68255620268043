// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/charming'
import '@/js/components/vh'
import '@/js/components/navigation'
import '@/js/components/scroll'
import '@/js/components/lazy-image'
import '@/js/components/slider'
import '@/js/components/masonry'
import '@/js/components/accordion'
import '@/js/components/map'
import '@/js/helpers/media'
import '@/js/components/back'
