import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  let hasHistory = false

  window.addEventListener('beforeunload', () => {
    hasHistory = true
  })

  Array.from(document.querySelectorAll('[data-back-projects]')).forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault()

      const href = e.currentTarget.href

      if (window.history.length > 2) {
        window.history.go(-1)
      }

      setTimeout(() => {
        if (!hasHistory) {
          window.location.href = href
        }
      }, 200)

      return false
    })
  })
})
