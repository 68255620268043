import Colcade from 'colcade'
import Masonry from 'masonry-layout'
import docReady from '@/js/helpers/doc-ready'
import scroll from '@/js/components/scroll'
import checkImages from '@/js/components/lazy-image'
import { getParameterByName, updateUrlParameter } from '@/js/components/url'

const axios = require('axios')
// eslint-disable-next-line
let colc = null
// eslint-disable-next-line
let msnry = null

let paged = 1
let loadMoreObserver = null
let scrollPosition = 0

scroll.on('scroll', (obj) => {
  scrollPosition = obj.scroll.y
})

window.addEventListener('beforeunload', function () {
  window.history.replaceState({
    scrollTop: scrollPosition
  }, '', window.location.href)
})

const getSearchProjects = params => {
  const p = {
    ...params,
    action: 'load_search_projects'
  }

  axios
    .get(window.ajaxurl, {
      params: p
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        document.getElementById('ajax-search-projects').insertAdjacentHTML('beforeEnd', data)
        const newItems = document.querySelectorAll('[data-masonry-item]:not(.is-in-position)')
        msnry.appended(newItems)
        Array.from(newItems).forEach(el => {
          el.classList.add('is-in-position')
        })
        checkImages()

        const url = updateUrlParameter(window.location.href, 'projects', 'all')
        window.history.replaceState({
          scrollTop: scrollPosition
        }, '', url)
      }
    })
}

const getSearchArticles = params => {
  const p = {
    ...params,
    action: 'load_search_articles'
  }

  axios
    .get(window.ajaxurl, {
      params: p
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        document.getElementById('ajax-search-articles').insertAdjacentHTML('beforeEnd', data)
        checkImages()

        const url = updateUrlParameter(window.location.href, 'articles', 'all')
        window.history.replaceState({
          scrollTop: scrollPosition
        }, '', url)
      }
    })
}

const getProjects = (params) => {
  paged++

  const p = {
    ...params,
    page: paged
  }
  axios
    .get(window.ajaxurl, {
      params: p
    })
    .then(res => res.data)
    .then(data => {
      if (data) {
        document.getElementById('ajax-projects').insertAdjacentHTML('beforeEnd', data)
        const newItems = document.querySelectorAll('[data-masonry-item]:not(.is-in-position)')
        msnry.appended(newItems)
        Array.from(newItems).forEach(el => {
          el.classList.add('is-in-position')
        })
        checkImages()
        const url = updateUrlParameter(window.location.href, 'page', paged)
        window.history.replaceState({
          scrollTop: scrollPosition
        }, '', url)

        if (window.lastPage && '' + paged === window.lastPage) {
          const loadMoreButton = document.querySelector('[data-load-more]')
          if (loadMoreButton && loadMoreObserver) {
            loadMoreObserver.unobserve(loadMoreButton)
            const pagination = document.getElementById('pagination')
            if (pagination) {
              pagination.remove()
            }
          }
        }

        scroll.update()
      }
    })
}

docReady(() => {
  const currentState = window.history.state
  if (currentState && currentState.scrollTop) {
    scroll.scrollTo(currentState.scrollTop, {
      duration: 0
    })
  }

  const colcadeElements = document.querySelectorAll('[data-colcade]')

  if (colcadeElements.length) {
    // eslint-disable-next-line
    colc = new Colcade('[data-colcade]', {
      columns: '[data-colcade-col]',
      items: '[data-colcade-item]'
    })

    Array.from(colcadeElements).forEach(el => {
      el.classList.add('colcade-initialized')
    })

    scroll.update()
  }

  const masonryElements = document.querySelector('[data-masonry]')
  if (masonryElements) {
    // eslint-disable-next-line
    msnry = new Masonry(masonryElements, {
      itemSelector: '[data-masonry-item]'
    })

    Array.from(document.querySelectorAll('[data-masonry-item]')).forEach(el => {
      el.classList.add('is-in-position')
    })

    scroll.update()
  }

  // AJAX
  const loadMoreButton = document.querySelector('[data-load-more]')

  if (loadMoreButton) {
    if ('IntersectionObserver' in window) {
      loadMoreObserver = new window.IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            getProjects({
              action: 'load_more_projects'
            })
          }
        })
      }, {
        rootMargin: '0px 0px 250px 0px'
      })

      loadMoreObserver.observe(loadMoreButton)
    }
  }

  if (getParameterByName('page')) {
    paged = getParameterByName('page')
  }

  if (window.pagedNum) {
    paged = window.pagedNum
  }

  const loadSearchProjectsButton = document.querySelector('[data-search-projects]')

  if (loadSearchProjectsButton) {
    loadSearchProjectsButton.addEventListener('click', e => {
      e.preventDefault()
      const s = loadSearchProjectsButton.getAttribute('data-search-projects')
      const rest = loadSearchProjectsButton.getAttribute('data-rest')
      getSearchProjects({
        s,
        rest
      })
      loadSearchProjectsButton.classList.add('hide')
    })
  }

  const loadSearchArticlesButton = document.querySelector('[data-search-articles]')

  if (loadSearchArticlesButton) {
    loadSearchArticlesButton.addEventListener('click', e => {
      e.preventDefault()
      const s = loadSearchArticlesButton.getAttribute('data-search-articles')
      const rest = loadSearchArticlesButton.getAttribute('data-rest')

      getSearchArticles({
        s,
        rest
      })
      loadSearchArticlesButton.classList.add('hide')
    })
  }
})
