import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'
import scroll from '@/js/components/scroll'
import isMobile from '@/js/helpers/media'

class Accordion {
  constructor (el) {
    this.el = el
    this.head = el.querySelector('[data-accordion-head]')
    this.button = el.querySelector('[data-accordion-button]')
    this.body = el.querySelector('[data-accordion-body]')
    this.active = false

    this.tl = () => gsap.timeline({
      defaults: {
        duration: this.active ? 0.6 : 0.3,
        ease: 'expo.out'
      },
      onComplete () {
        scroll.update()
      }
    })

    this.init()
    this.initEvents()
  }

  openAccordion () {
    this.active = true
    this.el.classList.add('accordion-open')

    const tl = this.tl()

    tl.to(this.body, {
      height: this.body.scrollHeight,
      opacity: 1,
      pointerEvents: 'initial'
    }, 0)

    tl.to(this.head, {
      paddingTop: isMobile ? 0 : 45
    }, 0)

    tl.to(this.button, {
      y: isMobile ? 0 : 45,
      rotate: -90
    }, 0)

    tl.set(this.body, {
      clearProps: 'all'
    })
  }

  closeAccordion () {
    this.active = false
    this.el.classList.remove('accordion-open')

    const tl = this.tl()

    tl.set(this.body, {
      height: this.body.scrollHeight,
      pointerEvents: 'none'
    })

    tl.to(this.head, {
      paddingTop: 0
    }, 0)

    tl.to(this.button, {
      y: 0,
      rotate: 90
    }, 0)

    tl.to(this.body, {
      height: this.head.getBoundingClientRect().height,
      opacity: 0
    }, 0)

    tl.set([this.head, this.button], {
      clearProps: 'all'
    })
  }

  toggleAccordion (e) {
    if (this.active) {
      this.closeAccordion()
    } else {
      this.openAccordion()
    }
  }

  init () {
    const tl = this.tl()

    tl.set(this.body, {
      height: this.head.getBoundingClientRect().height,
      opacity: 0,
      pointerEvents: 'none'
    })

    this.el.classList.add('accordion-initialized')
  }

  initEvents () {
    const clickHandler = e => this.toggleAccordion(e)
    this.head.addEventListener('click', clickHandler)
    this.button.addEventListener('click', clickHandler)
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-accordion]')).forEach(el => {
    // eslint-disable-next-line
    const accordion = new Accordion(el)
  })
})
