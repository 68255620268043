import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'
import scroll from '@/js/components/scroll'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.tfp-slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 4500,
      // draggable: false,
      // accessibility: false
      on: {
        ready () {
          scroll.update()
        },
        change () {
          scroll.update()
        }
      }
    })
  })
})
