import charming from 'charming'
import docReady from '@/js/helpers/doc-ready'
// import { gsap } from 'gsap'
import scroll from '@/js/components/scroll'

docReady(() => {
  const charmingElements = document.querySelectorAll('[data-charming]')

  if (charmingElements.length) {
    Array.from(charmingElements).forEach(el => {
      charming(el, {
        split: function (delimiter) {
          // the delimiter here is the space character
          return delimiter.split(' ')
        },
        setClassName: function (index, elem) {
          return `word-${index} charming-word`
        }
      })

      setTimeout(() => {
        el.classList.add('charming-initialized')

        setTimeout(() => {
          scroll.update()
        }, 600)
      }, 33)

      scroll.update()
    })
  }
})
