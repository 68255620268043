import 'intersection-observer'
import scroll from '@/js/components/scroll'
import docReady from '@/js/helpers/doc-ready'

const checkImages = () => {
  const lazyImages = [].slice.call(document.querySelectorAll('.lazy-media__image.not-loaded'))

  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new window.IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          const lazyImage = entry.target
          lazyImage.src = lazyImage.dataset.src
          lazyImage.srcset = lazyImage.dataset.srcset
          lazyImage.classList.remove('not-loaded')
          lazyImage.classList.add('loaded')
          lazyImageObserver.unobserve(lazyImage)

          scroll.update()
        }
      })
    })

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage)
    })
  } else {
    // Possibly fall back to event handlers here
  }
}

docReady(() => {
  checkImages()
})

export default checkImages
