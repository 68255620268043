import LocomotiveScroll from 'locomotive-scroll'
import 'locomotive-scroll/dist/locomotive-scroll.css'
import docReady from '@/js/helpers/doc-ready'
import throttle from '@/js/helpers/throttle'
import { gsap } from 'gsap'

// Map number x from range [a, b] to [c, d]
// const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c
// const clamp = (num, min, max) => num <= min ? min : num >= max ? max : num

// eslint-disable-next-line
const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  smooth: true
})

docReady(() => {
  // const isSingleProject = document.body.classList.contains('single-book')
  // const introMedia = document.querySelector('.general-intro__image')
  // if (isSingleProject && introMedia) {
  //   scroll.on('scroll', args => {
  //     // Get all current elements : args.currentElements
  //     for (const key of Object.keys(args.currentElements)) {
  //       if (args.currentElements[key].el.classList.contains('general-intro__media')) {
  //         const progress = args.currentElements[key].progress
  //         const rect = introMedia.getBoundingClientRect()
  //         const remainingWidth = rect.width - rect.height

  //         const correctedProgress = clamp(map(clamp(progress, 0.5, 1), 0.5, 1, 0, 1), 0, 1)

  //         gsap.set(introMedia, {
  //           x: -1 * (correctedProgress * remainingWidth)
  //         })
  //       }
  //     }
  //   })
  // }

  const bttButton = document.querySelector('.red-button--btt')

  if (bttButton) {
    scroll.on('scroll', throttle(args => {
      const showButton = args.scroll.y > 100
      gsap.to(bttButton, {
        duration: showButton ? 0.3 : 0.2,
        ease: showButton ? 'expo.out' : 'expo.inOut',
        scale: showButton ? 1 : 0
      })
    }, 200))

    bttButton.addEventListener('click', () => {
      scroll.scrollTo('top', {
        duration: 400,
        easing: [0.25, 0.00, 0.35, 1.00]
      })
    })
  }

  scroll.update()
})

export default scroll
